// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
  display: flex;
  flex-direction: column;
  background-color: #95dada;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 25px;
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
}

.card-container:hover {
  transform: scale(1.05);
}
`, "",{"version":3,"sources":["webpack://./src/components/card/card.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,kCAAkC;EAClC,2BAA2B;EAC3B,wBAAwB;EACxB,oCAAoC;AACtC;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".card-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  background-color: #95dada;\r\n  border: 1px solid grey;\r\n  border-radius: 5px;\r\n  padding: 25px;\r\n  cursor: pointer;\r\n  -moz-osx-font-smoothing: grayscale;\r\n  backface-visibility: hidden;\r\n  transform: translateZ(0);\r\n  transition: transform 0.25s ease-out;\r\n}\r\n\r\n.card-container:hover {\r\n  transform: scale(1.05);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
